<template>
  <div class="InfoM_I">
    <div class="headNavs">
      <div style="display:flex">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect">
          <el-menu-item :index="i" v-for="(item,i) in categoryList" :key="i">{{item.name}}</el-menu-item>
        </el-menu>
        <div class="GlisCentent" @click="Clue()">内容管理</div>
      </div>
      <div class="headDetails">说明：对口业务官网信息采集，采集素材主要为业务，运营服务，信息与官网同步更新</div>
    </div>


    <!-- 列表 -->
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiReset" @click="showAdd()"><i class="el-icon-lock"></i>权限设置</div>
        <div class="NavLiReset" v-if="isFalse == false" @click="False(1)"><img class="Img_ti" src="../../assets/img/wangzhan.png" alt="">网站管理</div>
        <div class="NavLiQuery" v-if="isFalse == true" @click="showAdd1()"><i class="el-icon-plus"></i>创建分类</div>
        <div class="NavLiReset" v-if="isFalse == true" @click="False(2)"><i class="el-icon-tickets"></i>信息管理</div>
        <div class="NavLiDelte" v-if="isFalse == true" @click="Add_Del()"><i class="el-icon-delete"></i>批量删除</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入ID">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <div v-if="isFalse == false">
        <el-table
          :data="tableData"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header">
          <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
          <el-table-column label="标题" align="center">     
            <template #default="scope" >
               <div>
                 <el-link class="tttyt" :href='scope.row.url' target="_blank"> <i class="el-icon-paperclip"></i>&nbsp;{{scope.row.title}}</el-link>
               </div>
            </template>      
          </el-table-column>
          <el-table-column prop="website.name" label="来源" align="center">
            <template #default="scope" >
               <div>
                 <el-link class="tttyt" style="width:300px" :href='scope.row.website.base' target="_blank"> <i class="el-icon-paperclip"></i>&nbsp;{{scope.row.website.name}}</el-link>
               </div>
            </template>              
          </el-table-column>
          <el-table-column prop="updatetime" label="更新时间" align="center" width="150"></el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <div v-else> 
        <el-table
          :data="tableData2"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handhange">
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column prop="id" label="ID" width="115" align="center"></el-table-column>
          <el-table-column prop="category.name" label="分类" width="200" align="center"></el-table-column>
          <el-table-column label="网站名称" width="470" align="center">
             <template #default="scope" >
               <div>
                 <el-link class="tttyt" :href='scope.row.url' target="_blank"> <i class="el-icon-paperclip"></i>&nbsp;{{scope.row.name}}</el-link>
               </div>
             </template>            
          </el-table-column> 
          <el-table-column prop="keywords" label="关键词" width="270" align="center"></el-table-column>
          <el-table-column label="是否启用" width="100" align="center">
            <template #default="scope" >
              <div class="el_butto rere dsdtrt">
                  <el-switch
                    :disabled="scope.row.type == 'default' ? true : false "
                    v-model="scope.row.status"
                    active-value = 'normal'
                    inactive-value = 'hidden'
                    active-color="#1890FF"
                    @change="changeSwitch1($event,scope.row,scope.$index)"
                    inactive-color="#dcdfe6">
                  </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" >
            <template #default="scope">
              <div class="el_butto">
                <el-button type="text" @click="HandleSee(scope.row)" >查看</el-button>
              </div>
              <div class="el_butto">
                <el-button type="text" @click="seeEdit(scope.row)" >编辑</el-button>
              </div>
              <div class="el_butto rere">
                  <el-button
                  type="text"
                  class="red"
                  @click="Add_Del2(scope.row)"
                  >删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange1"
            :current-page="page1.currentPage"
            :page-size="page1.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog :title="titleMath" v-model="dialogVisible3" width="28%" >
      <el-form class="EditNewForm" label-width="100px" style="padding:0" v-if="isColle == 1">
        <el-form-item class="EditNewFormBtns" label="采集分类：">
          <el-input v-model="Row1.editReceiver" disabled></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="接收人：">
          <el-input v-model="Row1.realname" placeholder="请选择接收人" @focus="Foccewe()"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="FormBtns">
            <div class="FormBtnsQuery" @click="BtnEdit1()">确定</div>
            <div class="FormBtnsClose" @click="Close()">取消</div>
          </div>
        </el-form-item>
      </el-form>
      <el-form class="EditNewForm" label-width="100px" style="padding:0" v-if="isColle == 2">
        <el-form-item class="EditNewFormBtns" label="分类：" v-if="Xin == 'chuangjian'">
          <el-input v-model="Row1.editReceiver" disabled  ></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="分类：" v-if="Xin == 'baocun'">
          <el-select v-model="form.category_id">
            <el-option  v-for="item in DGata" :key="item.id" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="网站：">
          <el-input v-model="form.name" placeholder="请输入网站名称"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="地址：">
          <el-input v-model="form.url" placeholder="请输入网站链接"></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="采集类型：">
          <el-select  v-model="form.type" placeholder="请选择采集类型" @change="Chang_ses2" style="width: 100%;">
            <el-option  v-for="item in moduD" :key="item" :label="item.name" :value="item.id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="关键词：">
          <el-input type="textarea" v-model="form.keywords" placeholder="请输入关键词（用,分割关键字）" ></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns" label="采集规则：">
          <el-input type="textarea" placeholder="请输入采集规则" v-model="form.rules" ></el-input>
        </el-form-item>
        <el-form-item class="EditNewFormBtns">
          <div class="FormBtns">
            <div class="FormBtnsQuery" v-if="Xin == 'chuangjian'" @click="BtnAdd()">确定</div>
            <div class="FormBtnsQuery" v-if="Xin == 'baocun'" @click="BtnEdit()">保存</div>
            <div class="FormBtnsClose" @click="Close()">取消</div>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--选择选择接收人-->
    <el-dialog title="选择接收人" v-model="editVisible5" width="48.5%"> 
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
              <div>
                <img class="Blo_a1" src="" alt="">
                <span class="Blo_a2">{{item.name}}</span>
              </div>
              <div @click="Nice_x(item.id)" style="cursor: pointer;">
                <img class="Blo_a3" src="" alt="">
                <span class="Blo_a4">下级</span>
              </div>
            </div>
            <div >
              <el-checkbox-group v-model="Chan_TARR" @change="bindCheckBox">
                <div class="Blo_b" >
                  <el-checkbox v-for="item in StaffList" :key="item" :label="item"  >
                    <img :src="item.full_avatar" alt="">
                    <span>{{item.realname}}</span>
                    </el-checkbox>
                </div>                 
              </el-checkbox-group>
            </div>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div v-if="Chan_Tonx.length != 0">已选：{{Chan_Tonx.length}}个员工</div>
          <div v-if="Chan_Tonx.length == 0">已选：0个员工</div>
          <div v-if="Chan_Tonx.length != 0">
            <div class="tyhh"  v-for="(item,index) in Chan_Tonx" :key="item" :label="item" >
              <img :src="item.full_avatar" alt="">
              <span>{{item.realname}}</span>
              <i class="el-icon-close" @click="Close_N(index)" style="float:right;padding:20px 50px;cursor: pointer;"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button type="primary" @click="Close_4()">保存设置</el-button>
        </div>
      </div> 
    </el-dialog>

    <!--职称管理-->
    <el-dialog title="内容管理" v-model="editVisible3" width="36%">
      <div class="Tabbl rrrTT">
        <el-table
        :data="CGata"
        class="table"
        header-cell-class-name="table-header">
        <el-table-column label="信息类别" width="240" align="center" >
          <template #default="scope" >
              <div class="El_pop dasdasd">
                <el-input :disabled = "scope.row.type == 'default' ? true : false"  v-model="scope.row.name" placeholder="类别"></el-input>
              </div>
          </template>
        </el-table-column>
        <el-table-column label="设置" width="180" align="center">
            <template #default="scope">
            <div v-if="scope.row.type == 'default'" @click="AS(scope.row)">预设</div>
            <div v-if="scope.row.type == 'custom'">自定义</div>
            </template>
        </el-table-column>
        <el-table-column label="是否启用" width="100">
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <el-switch
                  :disabled="scope.row.type == 'default' ? true : false "
                  v-model="scope.row.status"
                  active-value = 'normal'
                  inactive-value = 'hidden'
                  active-color="#1890FF"
                  @change="changeSwitch2($event,scope.row,scope.$index)"
                  inactive-color="#dcdfe6">
                </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" >
          <template #default="scope" >
            <div class="el_butto rere dsdtrt">
                <i class="el-icon-delete" @click="handleDelete2(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>
      </el-table>
      </div>
      <div class="YADD" @click="Add_leibie2"><i class="el-icon-plus"></i>创建类别</div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button @click="editVisible3 = false">取 消</el-button>
          <el-button type="primary" @click="NabCH_BTN" style="margin-right:24px">确 定</el-button>
        </div>
      </div>  
    </el-dialog>
  </div>
</template> 

<script>
import axios from 'axios'
export default {
  name: "basetable",
  data() {
    return {
      isFalse:false,
      dialogVisible3:false,
      editVisible3:false,
      editVisible5:false,
      tableData:[],
      tableData2:[],
      categoryList:[],
      DepartmentList:[],
      StaffList:[],
      Parents:[],
      Chan_TARR:[],
      Chan_Tonx:[],
      People_jj:'',
      receiver:[],
      DGata:[],
      CGata:[],
      moduD:[{id:'url',name:'网页'},{id:'api',name:'接口'}],
      page: {currentPage: 1,pageSize: 10},// 分页
      page1: {currentPage: 1,pageSize: 10},// 分页
      search: {
        keyword: "",
        category_id: 1,
      },
      Row1:{
        editReceiver:'',
        realname:'',
      },
      form:{
        category_id:'',
        name:'',
        url:'',
        type:'',
        keywords:'',
        rules:'',
      },
      total:'',
      titleMath:'',
      categoryId:'',
      activeIndex:0,
      isColle:'',
      multipleSelection_id:'',
      Xin:''

    };
  },
  mounted() {
    var that = this
    that.loadTable()
    that.getCategory()
  },
  methods: {
    loadTable() { // 获取列表数据
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/media/categoryNews',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: {
            category_id:that.search.category_id,
            keyword:that.search.keyword
          },
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.DGata = res.data.data.categoryList
      })
    },
    getCategory(){  //导航栏
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/media/categoryList',
        data: {},
      }).then(function (res) {
        that.categoryList = res.data.data.list
      })
    },
    loadTable2(){
      var that = this
      axios({ 
        method: 'post',
        url: '/admin/workbench/media/websiteList', 
        data: {
          page: that.page1.currentPage,
          limit: that.page1.pageSize,
          filter:{
            category_id:that.search.category_id,
            keyword:that.search.keyword
          }
        },
      }).then(function (res) {
          if (res.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.total = res.data.data.total
            that.tableData2 = res.data.data.rows
          }
      })
    },
    handleCurrentChange(e){ //分页
      this.page.currentPage = e
      this.loadTable()
    },
    handleCurrentChange1(e){ //分页
      this.page1.currentPage = e
      this.loadTable2()
    },
    showAdd(){ //打开权限设置
      this.titleMath = '权限设置'
      this.dialogVisible3 = true
      this.isColle = 1
      this.Row1.editReceiver = this.categoryList[this.activeIndex].name
      this.categoryId = this.categoryList[this.activeIndex].id
    },
    showAdd1(){  //创建
      this.titleMath = '创建信息'
      this.dialogVisible3 = true
      this.Xin = 'chuangjian'
      this.isColle = 2
      this.Row1.editReceiver = this.categoryList[this.activeIndex].name
      this.form.category_id = this.categoryList[this.activeIndex].id
    },
    BtnEdit1(){  //确认权限设置
      var that = this
      axios({ 
          method: 'post',
          url: '/admin/workbench/media/editReceiver', 
          data: {
            id:that.categoryId,
            receiver:that.receiver
          },
        }).then(function (res) {
          // setTimeout(function(){
          //   that.editVisible5 = false;
          // },1)
          if(res.data.code == 1){
            that.$message.success(res.data.msg);
            that.dialogVisible3 = false
          }
        })
    },
    BtnAdd(){  //创建
      var that = this
      axios({ 
        method: 'post',
        url: '/admin/workbench/media/addWebsite', 
        data: {
          row: that.form
        },
      }).then(function (res) {
        if(res.data.code == 1){
          that.$message.success(res.data.msg);
          that.dialogVisible3 = false
          that.loadTable2()
        }
      })
    },
    Close(){
      this.dialogVisible3 = false
    },
    handleSelect(e){
      this.activeIndex = e
      this.search.category_id = this.categoryList[e].id
      if(this.isFalse == false){
        this.loadTable()
      }else{
        this.loadTable2()
      }
    },
    Close_4() {
      var that = this
      var aww = []
      var att = that.Chan_Tonx
      for (let i = 0; i < att.length; i++) {
        const element = att[i];
        aww.push(element.realname)
        that.receiver.push(element.id)
      }
      console.log(that.receiver)
      that.Row1.realname = aww.toString()
      that.editVisible5 = false;
      setTimeout(function(){
        that.editVisible5 = false;
      },1)    
    },
    False(e){
      this.page.currentPage = 1
      if(e == 1){
        this.isFalse = true
        this.loadTable2()
      }else{
        this.isFalse = false
        this.loadTable()
      }
    },
    Add_Del(){
      var that = this
      if (that.multipleSelection_id == '') {      //批量删除
        that.$message.warning('请勾选信息');
      } else {
        that.$confirm("确定要删除吗？", "提示", {
          type: "primary"
        }).then(() => {
          axios({
            method: 'delete',
            url: '/admin/workbench/media/delWebsite',
            data: {
              ids: that.multipleSelection_id
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.loadTable2()
              }
          }) 
        })
      }
    },
    Add_Del2(e) {  //单独删除
      var arr = []
      arr.push(e.id)
      var that = this
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          axios({
            method: 'delete',
            url: '/admin/workbench/media/delWebsite',
            data: {
              ids: arr
            },
          }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.loadTable2()
              }
          }) 
        })
    },
    seeEdit(e){
      console.log(e);
      var that = this
      that.titleMath = '编辑信息'
      that.dialogVisible3 = true
      that.Xin = 'baocun'
      that.isColle = 2
      that.form.category_id = e.category_id
      that.form.name = e.name
      that.form.url = e.url
      that.form.keywords = e.keywords
      that.form.rules = e.rules
      that.categoryId = e.id
      if (e.type == 'url') {
        that.form.type = '网页'
      } else if(e.type == 'api') {
        that.form.type = '接口'
      }
    },
    BtnEdit(){  //保存
      var that = this
      axios({
        method: 'put',
        url: 'admin/workbench/media/editWebsite',
        data: {
          id: that.categoryId,
          row:that.form
        },
      }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.dialogVisible3 = false
            that.loadTable2()
          }
      }) 

    },
    Foccewe() {    //接收人选择
      var that = this
      that.Chan_Tonx = []
      that.Chan_TARR = []
      that.editVisible5 = true 
      axios.get('/admin/company/staff/subListFilter',{   
          params:{
            id: 0
          }
        }).then(function (res) {
          if (res.code == 0) {
            alert('请求失败');  
          } else{ 
                that.DepartmentList = res.data.data.departmentList
                that.StaffList = res.data.data.staffList
                that.Parents = res.data.data.parents
          }
      })
    },
    Bloo_Black(e) {         // 通讯录选择
      var that = this
      axios.get('/admin/company/staff/subListFilter',{   
        params:{
          id: e
        }
      }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DepartmentList = res.data.data.departmentList
          that.StaffList = res.data.data.staffList
          that.Parents = res.data.data.parents
        }
      })
    },
    Nice_x(e) {             //通讯录下级
        var that = this
        axios.get('/admin/company/staff/subListFilter',{
            params:{
              id: e
            }
          }).then(function (res) {
            if (res.code == 0) {
              alert('请求失败');  
            } else{ 
              that.DepartmentList = res.data.data.departmentList
              that.StaffList = res.data.data.staffList
              that.Parents = res.data.data.parents
            }
        })
    },
    handhange(e) {  // 勾选
      var that = this
      var arr = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
        }
        that.multipleSelection_id = arr
      }
    },
    changeSwitch1(data,b,index) {
      var that = this
      axios({ 
        method: 'put',
        url: '/admin/workbench/media/editWebsite',
        data: {
          id:b.id,
          row: b
        },
      }).then(function (res) {
          if (res.code == 0) {
                that.$message.warning(res.data.msg);
          }else {
            that.editVisible1 = false
            that.$message.success(res.data.msg);
       
          }
      })
    },
    bindCheckBox(value){     //通讯录选择员工
      var that = this
      console.log(value)
      that.Chan_Tonx = value
      console.log(that.Chan_Tonx)
    },
    Clue(){
      var that = this
      this.editVisible3 = true
      axios.get('/admin/workbench/media/categoryList',{ 
      }).then(function (res) {
        that.CGata = res.data.data.list
      })
    },
    Add_leibie2() {  //创建导航类别
      var that = this
      var ATT = that.CGata
      var srt = {
        name: "新加",
        status: 'normal',
        type: "custom",
        value:0,
      }
      ATT.push(srt)
      that.CGata = ATT
    },
    NabCH_BTN() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/media/editCategoryMore',
        data: {
          content: that.CGata
        },
      }).then(function (res) {
          if (res.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.editVisible3 = false
            that.getCategory()
          }
      }) 
    },
    handleDelete2(index, row) {  // 删除导航类别
      var that = this
      console.log(row)
      var ATT = that.CGata
      if (row.type == 'default') {
      } else {
        for (let i = 0; i < ATT.length; i++) {
          const ele = ATT[i];
          if (ele.id == row.id) {
            ATT.splice(index,1);
          }
        }
        that.CGata = ATT
      }
    },
    BtnSearch(e){
      var that = this
      if(e == false){
        that.search.keyword = ''
      }
      if(that.isFalse == false){
        that.page.currentPage = 1;
        that.loadTable()
      }else{
        that.page1.currentPage = 1;
        that.loadTable2()
      }
    }
  },
};
</script>

<style scoped>
 
</style>